import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AtiraIcon } from '../../../../components/AtiraIcon';
import { AtiraSpaceGreyIcon } from '../../../../components/AtiraSpaceGreyIcon';
import { Button } from '../../../../components/Button';
import { Drawer } from '../../../../components/Drawer';
import { Flex } from '../../../../components/Flex';
import { Text } from '../../../../components/Text';
import i18n, { AppLangs } from '../../../../i18n';
import { Widget } from '../../../../model/widget/Widget';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { userSliceSelectors } from '../../../../redux/user/user.selector';
import { widgetSliceSelectors } from '../../../../redux/widget/widget.selector';
import { widgetActions } from '../../../../redux/widget/widget.slice';
import { AtiraToast } from '../../../../utils/AtiraToast';

type WidgetCustomizeDrawerProps = {
  open: boolean;
  onClose: VoidFunction;
};

export const WidgetCustomizeDrawer: React.FC<WidgetCustomizeDrawerProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const widgets = useAppSelector(widgetSliceSelectors.selectUserWidgets);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const drawerPlacement = i18n.language === AppLangs.AR ? 'left' : 'right';

  const onToggleWidget = async (widget: Widget) => {
    try {
      setLoading(true);

      await dispatch(
        widgetActions.EditUserWidget({
          widgetId: widget._id,
          userId,
          active: !widget.active,
        }),
      ).unwrap();
      await dispatch(widgetActions.getUserWidgets({ userId })).unwrap();

      AtiraToast.success(
        widget.active
          ? t('widget.disable.success')
          : t('widget.enable.success'),
      );
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      title={t('widget.customize')}
      onClose={onClose}
      open={open}
      placement={drawerPlacement}
    >
      <Text fontSize="l" fontWeight={'bold'}>
        {t('widget.customize.description')}
      </Text>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height={'80%'}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          width={'100%'}
          marginTop="s"
        >
          {widgets.map((widget) => (
            <Flex key={widget._id} width={'100%'}>
              <Flex
                alignItems="center"
                justifyContent="start"
                gap="s"
                width={'100%'}
              >
                <Flex
                  backgroundColor={theme.lightergray}
                  width={'80%'}
                  alignItems="center"
                  borderRadius="pill"
                >
                  <Text
                    fontSize="xm"
                    fontWeight={'bold'}
                    style={{ padding: '0.5rem' }}
                  >
                    {t(`dashboard.widget.title.${widget.name}`)}
                  </Text>
                </Flex>
                {widget.toggleAble ? (
                  <Tooltip
                    title={
                      widget.active ? t('common.disable') : t('common.enable')
                    }
                  >
                    <Button
                      padding="0.5rem"
                      backgroundColor="transparent"
                      loading={loading}
                      disabled={loading}
                      onClick={() => onToggleWidget(widget)}
                    >
                      <AtiraIcon
                        icon={widget.active ? faMinusCircle : faPlusCircle}
                        color="main"
                        size="3x"
                      />
                    </Button>
                  </Tooltip>
                ) : null}
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="s"
        >
          <Text fontSize="m" color="gray">
            {t('dashboard.widget.customize.more_widget')}
          </Text>
          <AtiraSpaceGreyIcon />
        </Flex>
      </Flex>
    </Drawer>
  );
};
