import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { Steps, StepsProps } from 'antd';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

import i18n, { AppLangs } from '../i18n';
import { Rounded } from '../theme/Rounded';
import { Shadow } from '../theme/Shadow';
import { Spacing } from '../theme/Spacing';
import { Button } from './Button';
import { Flex } from './Flex';

const StyledSteps = styled(Steps)`
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.md};
  padding: ${Spacing.s};
  box-shadow: ${Shadow.SM};
  overflow-x: scroll;
  display: flex;
  gap: ${Spacing.m};
  scrollbar-width: none;

  .ant-steps-item {
    white-space: normal !important;
    display: flex !important;
    overflow: visible;
    position: static !important;
    flex-grow: 0;
  }

  .ant-steps-item-container {
    display: flex !important;
    align-items: center !important;
    white-space: nowrap !important;
  }

  .ant-steps-item-title::after {
    width: 1rem !important;
    background-color: ${({ theme }) => theme.main} !important;
  }

  .anticon {
    height: 100%;
  }
`;

const StepChangeButtonsWarpper = styled(Flex)`
  justify-content: space-between;
  position: sticky;
  bottom: ${Spacing.l};
  z-index: 9999;
  margin-top: auto;
`;

interface AtiraStepperProps extends StepsProps {
  components: ReactNode[];
  items: StepsProps['items'];
  style?: React.CSSProperties;
  fixed?: boolean;
}

export const AtiraStepper: React.FC<AtiraStepperProps> = ({
  items,
  components,
  style,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  const isNextStepDisabled = items?.[currentStep + 1]?.disabled;

  const Component = components[currentStep]!;

  return (
    <Flex flex={1} justifyContent="center" gap="l" style={style}>
      <Flex width={'100%'} flexDirection="column" padding="l" gap="m">
        <StyledSteps
          direction="horizontal"
          current={currentStep}
          initial={0}
          onChange={setCurrentStep}
          items={items}
        />
        {Component}
        <StepChangeButtonsWarpper>
          <Button
            icon={i18n.language === AppLangs.AR ? faArrowRight : faArrowLeft}
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
            iconWidth="lg"
          />
          <Button
            icon={i18n.language === AppLangs.AR ? faArrowLeft : faArrowRight}
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={
              currentStep === components.length - 1 || isNextStepDisabled
            }
            iconWidth="lg"
          />
        </StepChangeButtonsWarpper>
      </Flex>
    </Flex>
  );
};
