import { useTranslation } from 'react-i18next';

import { AtiraSpaceGreyIcon } from '../../components/AtiraSpaceGreyIcon';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';

export const Subscription: React.FC = () => {
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      padding="s"
      gap="xxl"
    >
      <Text fontSize="xxl">{t('subscription.page.title')}</Text>
      <Flex
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
        gap="xl"
        maxWidth={'40rem'}
      >
        <Text fontSize="xl" align="center">
          {`${t('subscription.status')} ${loggedInUser?.kind}`}
        </Text>
        <Text fontSize="l" style={{ whiteSpace: 'pre-line' }}>
          {t('subscription.info')}
        </Text>
        <Text fontSize="l" style={{ whiteSpace: 'pre-line' }}>
          {t('subscription.info.contact', { phone: '+90 533 558 90 40 - sales@atiraspace.com' })}
        </Text>
      </Flex>
      <AtiraSpaceGreyIcon />
    </Flex>
  );
};
