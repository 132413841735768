import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { DefaultTheme } from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import i18n, { AppLangs } from '../../../i18n';
import { KanbanColumn as KanbanColumnModel } from '../../../model/kanban/KanbanColumn';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { KanbanCard } from './KanbanCard';
import { KanbanCardCreateDrawer } from './KanbanCardCreateDrawer';

enum ColumnsNames {
  CLOSED_WIN = 'Closed-win',
  CLOSED_LOST = 'Closed-lost',
  CLOSED_TO_COMPETITION = 'Closed-to-competition',
}

const getColumnHeaderBorderColor = (
  columnName: string,
  theme: DefaultTheme,
) => {
  switch (columnName) {
    case ColumnsNames.CLOSED_WIN:
      return theme.green;
    case ColumnsNames.CLOSED_LOST:
    case ColumnsNames.CLOSED_TO_COMPETITION:
      return theme.red;
    default:
      return theme.main;
  }
};

const StyledKanbanColumn = styled(Flex)`
  flex-direction: column;
  gap: 0.75rem;
  width: 18rem;
  height: 98%;
  margin-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.m};
  margin-left: ${i18n.language === AppLangs.AR ? Spacing.m : '0'};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 88%;
  }
`;

const ColumnHeader = styled(Flex)<{ columnName: string }>`
  border: 2px solid
    ${({ columnName, theme }) => getColumnHeaderBorderColor(columnName, theme)};
  flex-direction: column;
  gap: 0.1rem;
  padding: ${Spacing.s};
  border-radius: ${Rounded.xl};
  background-color: ${(props) => props.theme.white};
`;

const KanbanCardsWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
  padding: ${Spacing.s} ${Spacing.s} 0 ${Spacing.s};
  background-color: ${(props) => props.theme.lightgray};
  border: 2px solid ${(props) => props.theme.lightgray};
  border-radius: ${Rounded.xl};
  position: relative;
  overflow: hidden;
`;

const KanbanCradsInnerWrapper = styled(Flex)<{ canAddCard: boolean }>`
  height: ${({ canAddCard }) => (canAddCard ? 'calc(100% - 3rem)' : '100%')};
  flex-direction: column;
  gap: ${Spacing.s};
  overflow-y: scroll;
  border-radius: ${Rounded.xl} ${Rounded.xl} ${Rounded.none} ${Rounded.none};

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AddKanbanCardButton = styled(Button)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3rem;
  border-radius: ${Rounded.none};
  margin: 0;
  background-color: ${(props) => props.theme.lightgray};
  text-align: start;
  display: flex;
  align-items: center;
  gap: ${Spacing.m};
`;

const ColumnHeaderTitle = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
`;

type KanbanColumnProps = {
  column: KanbanColumnModel;
};

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ column }) => {
  const { t } = useTranslation();

  const [kanbanCardCreateDrawerVisible, setKanbanCardCreateDrawerVisible] =
    useState(false);
  const [currentKanbanColumn, setCurrentKanbanColumn] =
    useState<KanbanColumnModel | null>(null);

  const canAddCard = column.default !== true;

  const openKanbanCardDrawer = () => {
    setCurrentKanbanColumn(column);
    setKanbanCardCreateDrawerVisible(true);
  };

  const totalAmount = column.cards
    ?.map((card) => Number(card.amount))
    .reduce((total, acc) => total + acc, 0);

  return (
    <StyledKanbanColumn>
      <ColumnHeader columnName={column.name}>
        <Flex justifyContent="space-between" alignItems="center" width={'100%'}>
          <ColumnHeaderTitle>{column.name}</ColumnHeaderTitle>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center" width={'100%'}>
          <Text align="center">
            {column.cards?.length} {t('common.deals')}
          </Text>
          <Text style={{ paddingRight: '0.55rem' }}>{totalAmount}$</Text>
        </Flex>
      </ColumnHeader>

      <KanbanCardsWrapper height={'100%'}>
        <KanbanCradsInnerWrapper canAddCard={canAddCard}>
          {column.cards?.map((c) => (
            <KanbanCard card={c} column={column} key={c._id} />
          ))}
        </KanbanCradsInnerWrapper>

        {canAddCard ? (
          <AddKanbanCardButton onClick={openKanbanCardDrawer}>
            <AtiraIcon icon={faPlus} color="darkerSub" size="2x" />
            <Text color="darkerSub" fontSize="m">
              {t('common.add')}
            </Text>
          </AddKanbanCardButton>
        ) : null}
      </KanbanCardsWrapper>

      <KanbanCardCreateDrawer
        kanbanColumn={currentKanbanColumn}
        isOpen={kanbanCardCreateDrawerVisible}
        onClose={() => setKanbanCardCreateDrawerVisible(false)}
      />
    </StyledKanbanColumn>
  );
};
