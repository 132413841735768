import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { Template } from '../../model/dto/TemplateDto';
import { FormInput } from '../../model/form/FormInput';
import { useAppSelector } from '../../redux/store';
import { templateSliceSelectors } from '../../redux/template/template.selector';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const TemplateContainer = styled(Flex)`
  border-radius: ${Rounded.lg};
  background-color: ${(props) => props.theme.gray};
  width: 20rem;
  height: 17rem;
  padding: ${Spacing.m};
`;

const TemplatesContainer = styled(Flex)`
  border-radius: ${Rounded.lg};
  padding: ${Spacing.l};
  background-color: ${(props) => props.theme.black};
`;

const TemplateLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.black};
  padding: 0.4rem ${Spacing.m};
  border-radius: ${Rounded.md};
`;

const TemplateViewBtn = styled(Button)`
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.black};
  padding: 0.8rem ${Spacing.m};
  border-radius: ${Rounded.md};
`;

const TemplateInputs = styled(Input)`
  border-radius: ${Spacing.m};
  border: 0;
  outline: none;
  padding: ${Spacing.s} ${Spacing.m};
  font-size: 1.3rem;
`;

export const TemplatesCards: React.FC = () => {
  const { t } = useTranslation();

  const [activeForm, setActiveForm] = useState<Template | undefined>(undefined);

  const templates = useAppSelector(templateSliceSelectors.selectAllTemplates);

  return (
    <Flex
      flexDirection="column"
      gap="l"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      flexGrow="1"
      marginBottom="s"
    >
      <Flex gap="m" flexWrap="wrap" justifyContent="center" alignItems="center">
        {templates.map((x) => (
          <TemplateContainer>
            <Flex
              alignSelf="flex-end"
              justifyContent="space-evenly"
              alignItems="center"
              width="100%"
            >
              <Flex>
                <TemplateViewBtn onClick={() => setActiveForm(x)}>
                  {t('common.view')}
                </TemplateViewBtn>
              </Flex>
              <Flex>
                <TemplateLink to={'/'}>
                  {t('templates.card.use_template')}
                </TemplateLink>
              </Flex>
            </Flex>
          </TemplateContainer>
        ))}
      </Flex>
      {activeForm === undefined ? null : (
        <TemplatesContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text fontSize="xl" color="white">
            {activeForm?.title}
          </Text>
          <Text fontSize="l" color="white">
            {t(`common.lower_case.${activeForm?.postSubmit}`)}
          </Text>
          <Flex>
            <Flex flexDirection="column">
              {activeForm?.inputs?.map((x: FormInput) => (
                <Flex
                  alignItems="center"
                  gap="m"
                  justifyContent="space-between"
                >
                  <TemplateInputs
                    type={x.type}
                    name={x.name}
                    placeholder={t(`common.${x.name}`).toLocaleUpperCase()}
                    required={x.required}
                  />
                  <Flex marginTop="s">
                    <Text fontSize="l" color="white">
                      {x.required === true
                        ? t('common.required')
                        : t('common.not_required')}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </TemplatesContainer>
      )}
    </Flex>
  );
};
