import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { GoogleButton } from '../../components/logins/GoogleButton';
import { LoginDto } from '../../model/user/dto/LoginDto';
import { LoginWithGoogleReturnPath } from '../../model/user/types/LoginWithGoogleReturnPath.enum';
import { useAppDispatch } from '../../redux/store';
import { userActions } from '../../redux/user/user.slice';
import { AtiraToast } from '../../utils/AtiraToast';
import { Regexes } from '../../utils/String';
import { RegisterWrapper } from './RegisterWrapper';

const TogglePageButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  margin: 0;
  color: ${(props) => props.theme.main};
  padding: 0;
  font-size: 1rem;
`;

const StyledButton = styled(Button)`
  height: 2.3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.main};
  font-size: 1rem;
  transition: background-color 0.2s;
  margin: 0;

  &:hover {
    background-color: ${(props) => props.theme.lightMain};
  }
`;

const StyledGoogleButton = styled(GoogleButton)`
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  height: 2.3rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.transparent};
  transition: all 0.2s;

  &:hover {
    border: 1px solid #c6c7c8;
    background-color: ${(props) => props.theme.darkSub};
  }
`;

const PasswordButton = styled(Button)`
  background-color: ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.main};
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
`;

const StyledInput = styled(Input)`
  width: 23rem;
  height: 2.3rem;
  border: 1px solid ${(props) => props.theme.lightergray};
  padding: 0.5rem;
  transition: border 0.2s;
  font-size: 1rem;

  &:focus {
    border: 1px solid #86b7fe !important;
  }
`;

const StyledHr = styled.hr`
  background-color: ${(props) => props.theme.darkerSub};
  height: 1px;
  border: 0;
  opacity: 1;
  width: 100%;
`;

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm<LoginDto>({
    defaultValues: { email: '', password: '' },
  });

  const { t } = useTranslation();
  const navigte = useNavigate();

  const onNext = async () => {
    try {
      setLoading(true);
      await dispatch(userActions.login(getValues())).unwrap();
      navigate('/dashboard', { replace: true });
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RegisterWrapper>
      <Flex
        width={'100%'}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="s"
      >
        <Controller
          name="email"
          rules={{ required: true, pattern: Regexes.EMAIL_REGEX }}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <StyledInput
              title={t('common.email')}
              type="email"
              value={value}
              onChange={onChange}
              placeholder={t('common.email')}
              id="email"
              valid={isEmpty(errors.email)}
              errorMessage={errors.email?.message || t('signup.error.email')}
            />
          )}
        />

        <Flex flexDirection="column" width={'100%'} gap="s">
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <StyledInput
                title={t('common.password')}
                value={value}
                type="password"
                onChange={onChange}
                placeholder={t('common.password')}
                id="password"
                valid={isEmpty(errors.password)}
                errorMessage={
                  errors.password?.message || t('signup.error.password')
                }
              />
            )}
          />

          <Flex justifyContent="space-between">
            <PasswordButton
              onClick={() => navigte('/forgot-password')}
              title={t('login.forgot_password')}
            />
          </Flex>
        </Flex>

        <Flex flexDirection="column" gap="m" width={'100%'} marginTop="m">
          <StyledButton
            loading={loading}
            onClick={handleSubmit(onNext)}
            title={t('common.login')}
          />

          <StyledHr />
          <StyledGoogleButton returnPath={LoginWithGoogleReturnPath.LOGIN}>
            {t('sign_in_with_google')}
          </StyledGoogleButton>
          <StyledHr />
        </Flex>
      </Flex>
      <Flex gap="s" alignItems="center">
        <Text>{t('login.do_not_have_account')}</Text>
        <TogglePageButton
          onClick={() => navigate('/signup')}
          title={t('common.signup')}
        />
      </Flex>
    </RegisterWrapper>
  );
};
