import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { PaginationProps, TableProps } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { Entry } from '../../model/entry/Entry';
import { GetLatestEntriesFilterDto } from '../../model/entry/dto/GetLatestEntriesFilterDto';
import { SearchEntryDto } from '../../model/entry/dto/SearchEntryDto';
import { PageMeta } from '../../model/meta/PageMeta';
import { dashboardSliceSelectors } from '../../redux/dashboard/dashboard.selector';
import { dashboardActions } from '../../redux/dashboard/dashboard.slice';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { entryActions } from '../../redux/entry/entry.slice';
import { formSliceSelectors } from '../../redux/forms/form.selector';
import { formActions } from '../../redux/forms/form.slice';
import { kanbanActions } from '../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { KanbanCardCreateDrawer } from '../kanban/components/KanbanCardCreateDrawer';
import { EntryConvertModal } from './components/EntryConvertModal';
import { EntryCreateModal } from './components/EntryCreateModal';
import { EntryDeleteModal } from './components/EntryDeleteModal';
import { EntryDetailsModal } from './components/EntryDetailsModal';
import { EntryTableBulkActionsHeader } from './components/EntryTableBulkActionsHeader';
import { EntryTableHeader } from './components/EntryTableHeader';
import { EntryUpdateDrawer } from './components/EntryUpdateDrawer';
import { EntiresTableWithDND } from './components/table/EntriesTableWithDND';
import { createEntriesTableColumns } from './utils/createEntriesTableColumns';
import { ContactTaskCreateDrawer } from './components/ContactTaskCreateDrawer';
import { ContactTaskReadDrawer } from './components/ContactTaskReadDrawer';

const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 0 1rem;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const PAGE_SIZE = 10;

export const EntriesRoute: React.FC = () => {
  const formMethods = useForm<GetLatestEntriesFilterDto | SearchEntryDto>({
    defaultValues: {
      formId: undefined,
      kind: undefined,
      range: undefined,
      keyword: '',
    },
  });
  const keyword = formMethods.watch('keyword');

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const [entryDeleteLoading, setEntryDeleteLoading] = useState(false);
  const [entryDeleteModalVisible, setEntryDeleteModalVisible] = useState(false);
  const [entryConvertModalVisible, setEntryConvertModalVisible] =
    useState(false);
  const [entryDetailsDrawerVisible, setEntryDetailsDrawerVisible] =
    useState(false);
  const [currentEntry, setCurrentEntry] = useState<Entry | null>(null);
  const [entryCreateModalVisible, setEntryCreateModalVisible] = useState(false);
  const [kanbanCardCreateDrawerVisible, setKanbanCardCreateDrawerVisible] =
    useState(false);
  const [entryUpdateDrawerVisible, setEntryUpdateDrawerVisible] =
    useState(false);
  const [taskCreateDrawerVisible, setTaskCreateDrawerVisible] = useState(false);
  const [taskReadDrawerVisible, setTaskReadDrawerVisible] = useState(false);

  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [newEntriesLoading, setNewEntriesLoading] = useState(false);

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const forms = useAppSelector(formSliceSelectors.selectMyForms);
  const entries = useAppSelector(entrySliceSelectors.selectLatestEntries);
  const searchEntries = useAppSelector(entrySliceSelectors.selectSearchEntries);
  const searchEntriesLoading = useAppSelector(
    entrySliceSelectors.selectSearchEntriesLoading,
  );
  const entriesLoading = useAppSelector(
    entrySliceSelectors.selectLatestEntriesLoading,
  );
  const entriesMeta = useAppSelector(
    entrySliceSelectors.selectLatestEntriesMeta,
  );
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  );
  const totalEntries = useAppSelector(
    dashboardSliceSelectors.selectDashboardStats,
  )?.entries.total;

  const deleteEntryWarning = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setEntryDeleteModalVisible(true);
  }, []);

  const convertEntryWarning = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setEntryConvertModalVisible(true);
  }, []);

  const onShowEntryDetails = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setEntryDetailsDrawerVisible(true);
  }, []);

  const onCreateDeal = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setKanbanCardCreateDrawerVisible(true);
  }, []);

  const updateEntry = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setEntryUpdateDrawerVisible(true);
  }, []);

  const createTask = useCallback((entry: Entry) => {
    setCurrentEntry(entry);
    setTaskCreateDrawerVisible(true);
  }, []);

  const previewTask = useCallback(
    (entry: Entry) => {
      setCurrentEntry(entry);
      setTaskReadDrawerVisible(true);
      dispatch(entryActions.getEntryById({ entryId: entry._id, userId }));
    },
    [dispatch, userId],
  );

  const deleteEntry = async () => {
    try {
      setEntryDeleteLoading(true);
      await dispatch(
        entryActions.deleteEntry({
          userId: userId,
          entryId: currentEntry?._id!,
        }),
      ).unwrap();
      setEntryDeleteModalVisible(false);
      await dispatch(
        entryActions.getLatestEntries({ userId, count: PAGE_SIZE, page: 0 }),
      ).unwrap();
      AtiraToast.success(t('entries.delete.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setEntryDeleteLoading(false);
    }
  };

  const onPageChange: TableProps['onChange'] = async (pagination) => {
    try {
      const canFetchMore =
        entriesMeta?.hasNext &&
        (pagination.current || 0) - entriesMeta.page === 1;

      if (canFetchMore) {
        setNewEntriesLoading(true);
        await dispatch(
          entryActions.getLatestEntries({
            userId,
            count: entriesMeta.count,
            page: entriesMeta.page,
            ...omitBy(formMethods.getValues(), isNil),
          }),
        ).unwrap();
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setNewEntriesLoading(false);
    }
  };

  const tableColumns = useMemo(() => {
    return createEntriesTableColumns(tableSettings?.columns || [], {
      delete: deleteEntryWarning,
      convert: convertEntryWarning,
      details: onShowEntryDetails,
      deal: onCreateDeal,
      createTask,
      previewTask,
      t,
      update: updateEntry,
    });
  }, [
    tableSettings?.columns,
    deleteEntryWarning,
    convertEntryWarning,
    onShowEntryDetails,
    onCreateDeal,
    createTask,
    previewTask,
    t,
    updateEntry,
  ]);

  const getPaginationItems: PaginationProps['itemRender'] = (page, type) => {
    switch (type) {
      case 'prev':
        return <Button padding="0.3rem 0.6rem" margin="0.5rem" title={'<'} />;
      case 'next':
        return <Button padding="0.3rem 0.6rem" margin="0.5rem" title={'>'} />;
      case 'page':
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(formActions.getMyForms({ userId }));
    dispatch(dashboardActions.getDashboardStats({ userId }));
    dispatch(entryActions.getUserTableSettings({ userId }));
    dispatch(kanbanActions.getUserDefaultKanban({ userId }));
  }, [userId, dispatch]);

  useEffect(() => {
    if (state?.useFilter) {
      const form = state.useFilter;
      formMethods.setValue('formId', form?._id);
    }

    dispatch(
      entryActions.getLatestEntries({
        userId,
        ...omitBy(formMethods.getValues(), isNil),
        ...PageMeta.create({ count: PAGE_SIZE, page: 0 }),
      }),
    );
  }, [dispatch, formMethods, state, userId]);

  return (
    <FormProvider {...formMethods}>
      <Flex flexDirection="column" overflowY="scroll">
        <SubHeader
          title={t('common.entries')}
          icon={faPlus}
          onClick={() => setEntryCreateModalVisible(true)}
          buttonTitle={t('common.create')}
        />

        <Wrapper>
          {selectedRows.length ? (
            <EntryTableBulkActionsHeader
              setSelectedEntriesIds={setSelectedRows}
              selctedEntriesIds={selectedRows}
            />
          ) : (
            <EntryTableHeader />
          )}

          <EntiresTableWithDND
            key={JSON.stringify(tableColumns)}
            columns={tableColumns}
            data={keyword ? searchEntries : entries}
            pagination={{
              showSizeChanger: false,
              pageSize: PAGE_SIZE,
              total: totalEntries,
              hideOnSinglePage: true,
              itemRender: getPaginationItems,
              // FIXME once there's a filter, current is stuck
              // Maybe, change total to filtered results size
            }}
            loading={
              (entriesLoading && !entries.length) ||
              newEntriesLoading ||
              searchEntriesLoading
            }
            selectedRowKeys={selectedRows}
            onSelectedRowKeysChange={setSelectedRows}
            onChange={onPageChange}
          />

          <EntryDeleteModal
            isOpen={entryDeleteModalVisible}
            onConfirm={deleteEntry}
            loading={entryDeleteLoading}
            onClose={() => setEntryDeleteModalVisible(false)}
          />

          <EntryConvertModal
            key={currentEntry?._id}
            isOpen={entryConvertModalVisible}
            onClose={() => setEntryConvertModalVisible(false)}
            entry={currentEntry}
          />

          <EntryCreateModal
            isOpen={entryCreateModalVisible}
            onClose={() => setEntryCreateModalVisible(false)}
            showSelect={forms.length > 0}
            key={currentForm?._id}
          />

          <EntryDetailsModal
            key={currentEntry?._id}
            isOpen={entryDetailsDrawerVisible}
            onClose={() => setEntryDetailsDrawerVisible(false)}
            entry={currentEntry}
          />

          <KanbanCardCreateDrawer
            entry={currentEntry}
            isOpen={kanbanCardCreateDrawerVisible}
            onClose={() => setKanbanCardCreateDrawerVisible(false)}
          />

          <EntryUpdateDrawer
            isOpen={entryUpdateDrawerVisible}
            entry={currentEntry}
            onClose={() => setEntryUpdateDrawerVisible(false)}
          />

          <ContactTaskCreateDrawer
            isOpen={taskCreateDrawerVisible}
            entry={currentEntry}
            onClose={() => setTaskCreateDrawerVisible(false)}
          />

          <ContactTaskReadDrawer
            isOpen={taskReadDrawerVisible}
            entry={currentEntry}
            onClose={() => setTaskReadDrawerVisible(false)}
          />
        </Wrapper>
      </Flex>
    </FormProvider>
  );
};
