import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons/faArrowsUpDown';
import React from 'react';
import {
  FieldArrayWithId,
  UseFieldArrayUpdate,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { SortableChild } from '../../../components/dnd/SortableChild';
import { CreateFormDto } from '../../../model/form/dto/CreateFormDto';
import { Breakpoints } from '../../../theme/Breakpoints';

const StyledInput = styled(Input)`
  width: 100%;
  height: 2.3rem;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.darkSub};
  font-size: 1.2rem;
  opacity: 1 !important;
  cursor: grab;
  &:disabled {
    cursor: grab;
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 3rem;
  }
`;

type SortableInputProps = {
  field: FieldArrayWithId<CreateFormDto, 'inputs'>;
  register: UseFormRegister<CreateFormDto>;
  update: UseFieldArrayUpdate<CreateFormDto, 'inputs'>;
  index: number;
  disabled?: boolean;
};

export const SortableInput: React.FC<SortableInputProps> = ({
  field,
  register,
  update,
  index,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <SortableChild id={field.id}>
      <Flex
        gap={'s'}
        alignItems="center"
        justifyContent="space-evenly"
        cursor="grab"
      >
        <Flex style={{ marginTop: '1.5rem' }}>
          <AtiraIcon icon={faArrowsUpDown} size="2x" />
        </Flex>

        <StyledInput
          title={t(`common.${field.name}`)}
          id={field.id}
          type={field.type}
          disabled
          border={`2px solid ${field.required ? theme.darkerSub : theme.lightgray}`}
          {...register(`inputs.${index}.value` as any)}
        />
        <Input
          id="reqriredCheck"
          type="checkbox"
          margin="1.6rem 0 0 0"
          disabled={disabled}
          onChange={(e) => {
            update(index, {
              name: field.name,
              required: e.target.checked,
              type: field.type,
              label: field.label,
            });
          }}
          checked={field.required}
          width="1.5rem"
          height="1.5rem"
        />
      </Flex>
    </SortableChild>
  );
};
