import { useTranslation } from 'react-i18next';

import { Modal } from '../../../components/modal/Modal';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppSelector } from '../../../redux/store';

interface FormReadModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const FormReadModal: React.FC<FormReadModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const formLink = `${process.env.REACT_APP_API_URL}/form/${currentForm?._id}/generate`;

  if (!currentForm) {
    return null;
  }

  return (
    <Modal
      key={currentForm._id}
      open={isOpen}
      onClose={onClose}
      title={`${t('forms.read.modal.title')}: ${currentForm.title}`}
    >
      <iframe
        width="400"
        height="400"
        style={{ border: 'none' }}
        src={formLink}
        title="preview form"
      />
    </Modal>
  );
};
