import { AtiraRoute } from '../AtiraRoute';
import { Features } from '../features/Features';
import { ProfileEdit } from '../profile/ProfileEdit';
import { Subscription } from '../subscription/Subscription';
import { Templates } from '../templates/Template';

export const PrivateHelpRouter = [
  {
    path: '/help/features',
    element: <AtiraRoute component={<Features />} />,
  },
  {
    path: '/help/templates',
    element: <AtiraRoute component={<Templates />} />,
  },
  {
    path: '/profile',
    element: <AtiraRoute component={<ProfileEdit />} />,
  },
  {
    path: '/subscription',
    element: <AtiraRoute component={<Subscription />} />,
  },
];
