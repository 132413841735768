import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faArrowsUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';

import i18n, { AppLangs } from '../../i18n';
import { Spacing } from '../../theme/Spacing';
import { AtiraIcon } from '../AtiraIcon';
import { Flex } from '../Flex';

const StyledContainer = styled(Flex)`
  width: 100%;
  position: relative;
`;

const StyledDraggable = styled.div`
  position: absolute;
  &:hover {
    cursor: grab;
  }
`;

type SortableChildProps = {
  id: string;
  children?: React.ReactNode;
  showDragIcon?: boolean;
};

export const SortableChild: React.FC<SortableChildProps> = ({
  id,
  children,
  showDragIcon = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const dragIconStyle = {
    left: i18n.language === AppLangs.AR ? Spacing.s : 'auto',
    right: i18n.language === AppLangs.AR ? 'auto' : Spacing.s,
    top: i18n.language === AppLangs.AR ? '0.65rem' : Spacing.s,
  };

  if (showDragIcon) {
    return (
      <StyledContainer ref={setNodeRef} style={style}>
        <Flex flex={1} width={'100%'}>
          {children}
        </Flex>
        <StyledDraggable {...attributes} {...listeners} style={dragIconStyle}>
          <AtiraIcon color="main" icon={faArrowsUpDownLeftRight} />
        </StyledDraggable>
      </StyledContainer>
    );
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
