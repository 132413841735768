import { Images } from '../assets';
import { AtiraImage, AtiraImageProps } from './Image';

type AtiraSpaceGreyIconProps = Omit<AtiraImageProps, 'src'>;

export const AtiraSpaceGreyIcon: React.FC<AtiraSpaceGreyIconProps> = (
  props,
) => {
  return (
    <AtiraImage
      src={Images.AtiraSpaceLogo}
      width={props.width || '15rem'}
      {...props}
    />
  );
};
