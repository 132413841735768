import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Rounded } from '../theme/Rounded';
import { Flex } from './Flex';

const SpinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyleSpinner = styled(Flex)`
  border: 0.3rem solid rgba(0, 0, 0, 0.1);
  border-top-color: ${({ color, theme }) => color || theme.main};
  border-radius: ${Rounded.xl};
  animation: ${SpinAnimation} 2s linear infinite;
`;

interface SpinnerProps {
  color?: string;
  size?: string;
  containerStyle?: React.CSSProperties;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color,
  size = '100%',
  containerStyle,
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height={size}
      style={
        containerStyle
          ? { margin: 'auto', aspectRatio: '1', ...containerStyle }
          : { margin: 'auto', aspectRatio: '1' }
      }
    >
      <StyleSpinner color={color} height={'100%'} width={'100%'} />
    </Flex>
  );
};
