import AtiraGrupIcon from './atira-grup-icon.svg';
import GoogleIcon from './google.svg';

export const Images = {
  HeroPhoto: require('./hero.jpg'),
  HeroBackground: require('./hero-bg.jpg'),
  FormTheme1: require('./form-theme1.png'),
  FormTheme2: require('./form-theme2.png'),
  FormTheme3: require('./form-theme3.png'),
  AtiraSpaceLogo: require('./atiraspace-logo.png'),
  FeaturesBackground: require('./features-bg.jpg'),
  FeaturesFirstCircle: require('./hero-first-circle-img.png'),
  FeaturesSecondCircle: require('./hero-second-circle-img.png'),
  FeaturesFirstSection: require('./features-first-section.jpg'),
  FeaturesSecondSection: require('./features-second-section.jpg'),
  AtiraGrupIcon,
  GoogleIcon,
};
