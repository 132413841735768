import {
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { SpinnerFullScreen } from '../../components/SpinnerFullScreen';
import { SubHeader } from '../../components/SubHeader';
import { DNDContext } from '../../components/dnd/DNDContext';
import { DNDSortableDroppableContext } from '../../components/dnd/DNDSortableDroppableContext';
import { KanbanCard as KanbanCardModel } from '../../model/kanban/KanbanCard';
import { KanbanColumn as KanbanColumnModel } from '../../model/kanban/KanbanColumn';
import { UpdateKanbanCardDto } from '../../model/kanban/dto/UpdateKanbanCardDto';
import { kanbanSliceSelectors } from '../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';
import {
  getNewColumnsStateForDifferentColumnsDND,
  getNewColumnsStateForSameColumnDND,
} from './KanbanUtils';
import { KanbanCard } from './components/KanbanCard';
import { KanbanCardCreateDrawer } from './components/KanbanCardCreateDrawer';
import { KanbanCardMovingReasonModal } from './components/KanbanCardMovingReasonModal';
import { KanbanColumn } from './components/KanbanColumn';
import { KanbanHeader } from './components/KanbanHeader';

const Wrapper = styled(Flex)`
  padding: 0 ${Spacing.m};
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
`;

export const Kanban: React.FC = () => {
  /** Item being picked */
  const [activeItemId, setActiveItemId] = useState<
    UniqueIdentifier | undefined
  >();
  const [columns, setColumns] = useState<KanbanColumnModel[]>([]);
  // Figure out loading and debouncing
  const [kanbanCardCreateDrawerVisible, setKanbanCardCreateDrawerVisible] =
    useState(false);

  const [
    kanbanCardMovingReasonModalVisible,
    setKanbanCardMovingReasonModalVisible,
  ] = useState(false);

  const [reasonMessage, setReasonMessage] = useState<string | null>(null);
  const [currentEvent, setCurrentEvent] = useState<DragEndEvent | null>(null);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const kanban = useAppSelector(kanbanSliceSelectors.selectUserDefaultKanban);
  const kanbanLoading = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanbanLoading,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const getColumnByCardId = (itemId: UniqueIdentifier) => {
    for (let i = 0; i < columns?.length; i++) {
      const col = columns[i];
      if (col?.cards?.find((card) => card._id === itemId)) {
        return col;
      }
    }
  };

  const getColumnById = (itemId: UniqueIdentifier) => {
    return columns.find(({ _id }) => _id === itemId);
  };

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItemId(active.id);
  };

  const onDragConfirm = async (event: DragEndEvent, reasonMessage?: string) => {
    try {
      const { active, over } = event;

      const currentColumn =
        getColumnByCardId(over?.id!) || getColumnById(over?.id!);
      const sourceColumn = getColumnByCardId(active.id);

      if (!currentColumn || !over || active.id === over.id) {
        return;
      }

      let newColumnsState: {
        newOrder?: number;
        oldOrder?: number;
        newColumns: KanbanColumnModel[];
      };
      // If the card is being moved within the same column
      if (!sourceColumn || sourceColumn._id === currentColumn._id) {
        newColumnsState = getNewColumnsStateForSameColumnDND({
          currentColumn,
          columns,
          active,
          over,
        });
        if (newColumnsState.newOrder === newColumnsState.oldOrder) {
          newColumnsState.newOrder = undefined;
          newColumnsState.oldOrder = undefined;
        }
      } else {
        newColumnsState = getNewColumnsStateForDifferentColumnsDND({
          currentColumn,
          sourceColumn,
          columns,
          active,
          over,
        });
      }

      setColumns(newColumnsState.newColumns);

      const dto = omitBy(
        {
          cardId: active.id as string,
          columnId: currentColumn._id,
          userId,
          newOrder: newColumnsState.newOrder,
          oldOrder: newColumnsState.oldOrder,
          kanbanId: kanban?._id!,
          ...(reasonMessage ? { movingReason: reasonMessage } : {}),
        },
        isNil,
      ) as unknown as UpdateKanbanCardDto;

      await dispatch(kanbanActions.updateKanbanCard(dto)).unwrap();

      if (reasonMessage) {
        AtiraToast.success(t('deals.drawer.moving_reason.success'));
      }
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setActiveItemId(undefined);
    }
  };

  const onSumbitMessage = (reasonMessage: string) => {
    setReasonMessage(reasonMessage);
    onDragConfirm(currentEvent!, reasonMessage);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { over, active } = event;

    const currentColumn =
      getColumnByCardId(over?.id!) || getColumnById(over?.id!);
    const sourceColumn = getColumnByCardId(active.id);

    const defaultColumns = columns.filter((column) => column.default);

    const showModal = defaultColumns.find(
      (defaultColumn) =>
        defaultColumn._id === currentColumn?._id &&
        currentColumn._id !== sourceColumn?._id,
    );

    if (showModal) {
      setCurrentEvent(event);
      setKanbanCardMovingReasonModalVisible(true);
    } else {
      onDragConfirm(event);
    }
  };

  const renderDragOverlay = () => {
    if (!activeItemId) {
      return null;
    }

    const activeCard = columns
      .flatMap((col) => col.cards)
      .find((card) => card?._id === activeItemId);

    if (!activeCard) {
      return null;
    }

    return <KanbanCard card={activeCard} />;
  };

  useEffect(() => {
    dispatch(kanbanActions.getUserDefaultKanban({ userId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kanban) {
      setColumns(kanban?.columns || []);
    }
  }, [kanban, kanban?.columns]);

  if (!columns.length && kanbanLoading) {
    return <SpinnerFullScreen />;
  }

  return (
    <Flex flexDirection="column" overflowY="hidden" flex={1}>
      <SubHeader
        buttonTitle={t('common.add')}
        icon={faPlus}
        onClick={() => setKanbanCardCreateDrawerVisible(true)}
        title={t('common.deals')}
      />

      <Wrapper>
        <KanbanHeader />
        <DNDContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Flex height={'100%'} width={'100%'}>
            {columns.map((col) => (
              <DNDSortableDroppableContext
                id={col._id}
                key={col._id}
                items={col.cards || []}
              >
                <KanbanColumn column={col} />
              </DNDSortableDroppableContext>
            ))}
            <DragOverlay
              dropAnimation={{
                duration: 500,
                easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
              }}
            >
              {renderDragOverlay()}
            </DragOverlay>
          </Flex>
        </DNDContext>

        <KanbanCardCreateDrawer
          isOpen={kanbanCardCreateDrawerVisible}
          onClose={() => setKanbanCardCreateDrawerVisible(false)}
        />

        <KanbanCardMovingReasonModal
          isOpen={kanbanCardMovingReasonModalVisible}
          onClose={() => setKanbanCardMovingReasonModalVisible(false)}
          onConfirm={onSumbitMessage}
        />
      </Wrapper>
    </Flex>
  );
};
