import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Spacing } from '../../theme/Spacing';
import { SortableInput } from './components/SortableInput';

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding-top: ${Spacing.l};
  border-top: 2px solid ${({ theme }) => theme.darkerSub};
  gap: ${Spacing.s};
  order: 2;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    border-right: ${({ theme }) =>
      i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
    border-left: ${({ theme }) =>
      i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};
    padding-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.xl};
    padding-left: ${i18n.language === AppLangs.AR ? Spacing.xl : '0'};
    padding-top: 0;
    border-top: 0;
    width: 63%;
    order: 1;
  }
`;

const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.s};
  order: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 35%;
    order: 2;
  }
`;

export const FormCreateStepInputs: React.FC = () => {
  const { control, register } = useFormContext<CreateFormDto>();

  const { fields, update, replace } = useFieldArray({
    control,
    name: 'inputs',
  });

  const { t } = useTranslation();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const inputOnDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((f) => f.id === active.id);
      const newIndex = fields.findIndex((f) => f.id === over.id);

      replace(arrayMove(fields, oldIndex, newIndex));
    }
  };

  return (
    <Flex width={'100%'} gap="m" alignItems="center" flexWrap="wrap">
      <LeftSideWrapper>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={inputOnDragEnd}
        >
          <SortableContext
            items={fields}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((field, i) => (
              <SortableInput
                disabled={field.type === InputTypes.EMAIL.toLowerCase()}
                key={field.id}
                field={field}
                index={i}
                register={register}
                update={update}
              />
            ))}
          </SortableContext>
        </DndContext>
      </LeftSideWrapper>

      <RightSideWrapper>
        <Text fontSize="m" color="darkerSub" style={{ lineHeight: '1.5rem' }}>
          {t('forms.create.step.2.form_inputs.note')}
        </Text>
      </RightSideWrapper>
    </Flex>
  );
};
