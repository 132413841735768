import { Checkbox, CheckboxOptionType, GetProp } from 'antd';
import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox.Group)`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  column-gap: 5rem;
  max-width: 20rem;

  .ant-checkbox .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.gray};
  }

  .ant-checkbox + span {
    font-size: 1.5rem;
    width: 5rem;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-50%, -70%);
  }
`;

type AtiraCheckBoxProps = {
  options: CheckboxOptionType[];
  value: string[];
  onChange: GetProp<typeof Checkbox.Group, 'onChange'>;
  style?: React.CSSProperties;
};

export const AtiraCheckBoxGroup: React.FC<AtiraCheckBoxProps> = (props) => {
  return <StyledCheckbox {...props} />;
};
