import React from 'react';
import { useTranslation } from 'react-i18next';

import { WarningModal } from '../../../components/WarningModal';

type EntryDeleteModalProps = {
  onConfirm: VoidFunction;
  loading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const EntryDeleteModal: React.FC<EntryDeleteModalProps> = ({
  onConfirm,
  loading,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <WarningModal
      title={t('entries.warning_modal.delete.title')}
      description={t('entries.warning_modal.delete.description')}
      isOpen={isOpen}
      onConfirm={onConfirm}
      loading={loading}
      onClose={onClose}
    />
  );
};
