import { Rate } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { Text } from '../../../components/Text';
import { Entry } from '../../../model/entry/Entry';
import { ContactTaskPriority } from '../../../model/entry/types/ContactTaskPriority.enum';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { getMediaUrl } from '../../../utils/Media';
import { TaskUpdateDrawer } from './ContactTaskUpdateDrawer';

const getTaskPriorityTextColor = (taskPriority?: ContactTaskPriority) => {
  switch (taskPriority) {
    case ContactTaskPriority.LOW:
      return 'green';
    case ContactTaskPriority.MEDIUM:
      return 'orange';
    case ContactTaskPriority.HIGH:
      return 'red';
  }
};

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

const TaskImage = styled(AtiraImage)`
  max-width: 4rem;
  max-height: 4rem;
  width: 100%;
  height: 100%;
  align-self: center;
`;

interface TaskReadDrawerProps {
  entry: Entry | null;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const ContactTaskReadDrawer: React.FC<TaskReadDrawerProps> = ({
  entry,
  isOpen = false,
  onClose,
}) => {
  const [updateDrawerVisible, setUpdateDrawerVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <Drawer
      key={entry?._id}
      title={t('task.read.drawer.title')}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flex={1} flexDirection="column" gap="m">
        <StyledFlex>
          <BoldText>{t('task.read.name')}</BoldText>
          <ValueText>{entry?.contactTask?.name}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('task.read.created_for')}</BoldText>
          <ValueText>{entry?.fields.name}</ValueText>
        </StyledFlex>

        {entry?.contactTask?.deadline ? (
          <StyledFlex>
            <BoldText>{t('common.due_date')}:</BoldText>
            <Text>
              {standardDate(new Date(entry?.contactTask?.deadline).toString())}
            </Text>
          </StyledFlex>
        ) : null}

        <StyledFlex>
          <BoldText>{t('common.priority')}:</BoldText>
          <Text
            style={{
              color: getTaskPriorityTextColor(entry?.contactTask?.priority),
            }}
          >
            {entry?.contactTask?.priority}
          </Text>
        </StyledFlex>

        {entry?.contactTask?.description ? (
          <Flex gap="s" flexWrap="wrap">
            <BoldText>{t('common.description')}:</BoldText>
            <Flex>
              <Text wordBreak="break-word">
                {entry?.contactTask?.description}
              </Text>
            </Flex>
          </Flex>
        ) : null}

        {entry?.contactTask?.image ? (
          <Flex gap="s" flexDirection="column" flexWrap="wrap">
            <BoldText>{t('common.image')}:</BoldText>
            <TaskImage preview src={getMediaUrl(entry.contactTask?.image)} />
          </Flex>
        ) : null}

        {entry?.contactTask?.rating ? (
          <Flex gap="s" flexDirection="column" flexWrap="wrap">
            <BoldText>{t('common.rating')}:</BoldText>
            <Rate count={10} disabled value={entry?.contactTask?.rating} />
          </Flex>
        ) : null}

        <StyledButton
          title={t('common.edit')}
          onClick={() => {
            onClose();
            setUpdateDrawerVisible(true);
          }}
        />
      </Flex>

      <TaskUpdateDrawer
        key={entry?._id}
        entry={entry}
        isOpen={updateDrawerVisible}
        onClose={() => setUpdateDrawerVisible(false)}
      />
    </Drawer>
  );
};
