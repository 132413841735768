import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faDiamond } from '@fortawesome/free-solid-svg-icons/faDiamond';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons/faEllipsis';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { Dropdown, MenuProps, TableProps, Tooltip } from 'antd';
import { isAfter } from 'date-fns/isAfter';
import { isToday } from 'date-fns/isToday';
import { isTomorrow } from 'date-fns/isTomorrow';
import { TFunction } from 'i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { EntriesTableSettings } from '../../../model/entries-table-settings/EntriesTableSettings';
import { Entry } from '../../../model/entry/Entry';
import { ContactTaskPriority } from '../../../model/entry/types/ContactTaskPriority.enum';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { Spacing } from '../../../theme/Spacing';
import { ThemeType } from '../../../theme/Theme';
import { standardDate } from '../../../utils/Date';
import { truncateString } from '../../../utils/String';
import { getTableCellClassName } from './getTableCellClassName';

const helpersFunctions = {
  isOutDue: (entry: Entry) => {
    const currentDate = new Date();

    return entry.contactTask?.deadline
      ? isAfter(currentDate, entry.contactTask.deadline)
      : false;
  },

  getButtonBackgoundColor: (entry: Entry): keyof ThemeType => {
    if (!entry.contactTask?.deadline) {
      return 'white';
    }

    const now = new Date();
    const deadline = new Date(entry.contactTask.deadline);

    if (entry.contactTask.done) {
      return 'gray';
    } else if (isToday(deadline)) {
      return 'orange';
    } else if (deadline < now) {
      return 'red';
    } else {
      return 'green';
    }
  },

  getButtonTitle: (entry: Entry, t: TFunction) => {
    let taskDeadLineDay = 0;
    let taskDeadLineMonth = 0;

    if (entry.contactTask?.deadline) {
      const taskDeadLine = new Date(entry.contactTask?.deadline);
      taskDeadLineMonth = taskDeadLine.getMonth() + 1;
      taskDeadLineDay = taskDeadLine.getDate();
    }

    if (entry.contactTask?.deadline) {
      if (isToday(entry.contactTask.deadline)) {
        return (
          <Text align="center" color="white">
            {t('common.today')}
          </Text>
        );
      } else if (isTomorrow(entry.contactTask.deadline)) {
        return (
          <Text color="white" align="center">
            {t('common.tomorrow')}
          </Text>
        );
      } else if (helpersFunctions.isOutDue(entry)) {
        return (
          <Text color="white" align="center">
            {t('common.out_due')}
          </Text>
        );
      } else {
        return `${taskDeadLineDay}/${taskDeadLineMonth}`;
      }
    } else {
      return truncateString(entry?.contactTask?.name || 'Task', 10);
    }
  },

  getPriorityTitle: (entry: Entry) => {
    if (!entry.contactTask?.priority) {
      return null;
    }

    let resultStr;

    switch (entry.contactTask.priority) {
      case ContactTaskPriority.HIGH:
        resultStr = 'P1';
        break;
      case ContactTaskPriority.MEDIUM:
        resultStr = 'P2';
        break;
      case ContactTaskPriority.LOW:
        resultStr = 'P3';
        break;
      default:
        resultStr = 'P1';
    }
    return (
      <Text align="center" color="white">
        {resultStr}
      </Text>
    );
  },
};

type FieldFunctions = {
  delete: ATVoidFunction<Entry>;
  convert: ATVoidFunction<Entry>;
  details: ATVoidFunction<Entry>;
  deal: ATVoidFunction<Entry>;
  update: ATVoidFunction<Entry>;
  createTask: ATVoidFunction<Entry>;
  previewTask: ATVoidFunction<Entry>;
  t: TFunction;
};

const StyledActionButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: ${Spacing.s};
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

const getTruncatedString = (value: string, length: number) => {
  if (value && value.length >= length) {
    return <Tooltip title={value}>{truncateString(value, length)}</Tooltip>;
  }

  return value;
};

const getFieldWidth = (field: string) => {
  switch (field) {
    case 'message':
    case 'phone':
    case 'description':
    case 'form':
      return '10rem';

    default:
      return '7rem';
  }
};

const getFieldInfo = (
  column: EntriesTableSettings['columns'][number],
  functions: FieldFunctions,
) => {
  const { field, enabled, order, toggleAble } = column;
  const {
    t,
    delete: deleteFn,
    convert,
    details,
    update,
    deal,
    createTask,
    previewTask,
  } = functions;
  const imgWidth = '20';

  switch (field) {
    case 'actions':
      return {
        title: t('common.actions'),
        key: 'actions',
        render: (entry: Entry) => {
          const items: MenuProps['items'] = [
            {
              key: 'g1',
              type: 'group',
              children: [
                {
                  key: '1',
                  label: (
                    <StyledActionButton onClick={() => update(entry)}>
                      <Flex gap="s">
                        <AtiraIcon icon={faPenToSquare} color="main" />
                        <Text color="main">{t('common.edit')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <StyledActionButton onClick={() => details(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faEye} color="main" />
                        <Text color="main">{t('common.preview')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '3',
                  label: (
                    <StyledActionButton onClick={() => deal(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faDiamond} color="main" />
                        <Text color="main">{t('common.deal')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: entry.kind === EntryKind.CONTACT,
                },
                {
                  key: '4',
                  label: (
                    <StyledActionButton onClick={() => convert(entry)}>
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faRotate} color="main" />
                        <Text color="main">{t('common.convert')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
                {
                  key: '5',
                  label: (
                    <StyledActionButton
                      width={imgWidth}
                      onClick={() => createTask(entry)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faCheck} color="main" />
                        <Text color="main">{t('common.task')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                  valid: !entry.contactTask && entry.kind === EntryKind.CONTACT,
                },
                {
                  key: '6',
                  label: (
                    <StyledActionButton
                      width={imgWidth}
                      onClick={() => deleteFn(entry)}
                    >
                      <Flex gap="s" alignItems="center">
                        <AtiraIcon icon={faTrashCan} color="main" />
                        <Text color="main">{t('common.delete')}</Text>
                      </Flex>
                    </StyledActionButton>
                  ),
                },
              ]
                .filter((item) => item.valid !== false)
                .map((child) => ({
                  ...child,
                  style: { padding: 4 },
                })),
            },
          ];

          return {
            props: { className: 'fixed-cell' },
            children: (
              <Flex justifyContent="center">
                <Dropdown
                  menu={{ items }}
                  placement="bottomLeft"
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                >
                  <Tooltip title={t('common.menu')}>
                    <Button
                      icon={faEllipsis}
                      backgroundColor="transparent"
                      padding="0"
                      color="black"
                    />
                  </Tooltip>
                </Dropdown>
              </Flex>
            ),
          };
        },
        reorder: true,
        center: true,
        width: '5rem',
        fixed: 'left',
        data: column,
      };

    case 'task':
      return {
        title: t('common.task'),
        key: 'actions',
        render: (entry: Entry) => {
          return {
            props: {
              className: 'fixed-cell',
            },

            children: entry.contactTask ? (
              <Flex justifyContent="space-between" gap="m" alignItems="center">
                <Button
                  color={entry.contactTask.deadline ? 'white' : 'black'}
                  width="100%"
                  padding="0.1rem"
                  margin="0"
                  border={entry.contactTask.deadline ? '0' : `1px solid black`}
                  onClick={() => previewTask(entry)}
                  backgroundColor={helpersFunctions.getButtonBackgoundColor(
                    entry,
                  )}
                  style={{ maxWidth: '6rem' }}
                >
                  {helpersFunctions.getButtonTitle(entry, t)}
                </Button>
                <Button
                  width="2rem"
                  padding="0.3rem"
                  margin="0"
                  onClick={() => previewTask(entry)}
                >
                  {helpersFunctions.getPriorityTitle(entry)}
                </Button>
              </Flex>
            ) : null,
          };
        },

        reorder: true,
        center: true,
        width: '5rem',
        fixed: 'left',
        data: column,
      };

    case 'name':
      return {
        title: t('common.name'),
        key: 'name',
        render: (entry: Entry) => {
          return (
            <Button
              onClick={() => details(entry)}
              backgroundColor="transparent"
              margin="0"
              padding="0"
              color="black"
            >
              {entry.fields?.name
                ? getTruncatedString(entry.fields.name, 30)
                : ''}
            </Button>
          );
        },
        sorter: (a: Entry, b: Entry) =>
          a.fields?.name.localeCompare(b.fields?.name),
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };

    case 'time':
      return {
        title: t('entries.table.header.submit_time'),
        key: 'time',
        render: ({ createdAt }: Entry) =>
          standardDate(new Date(createdAt), true),
        sorter: (a: Entry, b: Entry) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateA - dateB;
        },
        sortable: true,
        reorder: true,
        width: '9.5rem',
        data: column,
      };

    case 'form':
      return {
        title: t('entries.table.header.form_title'),
        key: 'formTitle',
        render: ({ parentForm }: Entry) =>
          parentForm?.title
            ? getTruncatedString(parentForm?.title, 25)
            : t('entries.table.entry.unknown_title'),
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };

    case 'phone':
      return {
        title: t('common.phone'),
        key: 'phone',
        render: ({ fields }: Entry) => {
          const onClick = () => {
            if (fields?.phone) {
              window.open(`https://wa.me/${fields.phone}`, '_blank');
            }
          };

          return fields?.phone ? (
            <Flex justifyContent="space-between" alignItems="center" gap="l">
              {getTruncatedString(fields.phone, 20)}

              <Tooltip
                title={t('entries.table.settings.columns.whatsapp.title')}
              >
                <Button
                  onClick={onClick}
                  backgroundColor="transparent"
                  padding="0"
                  margin="0"
                  color="green"
                  icon={faWhatsapp}
                  iconWidth="2x"
                />
              </Tooltip>
            </Flex>
          ) : null;
        },
        sortable: true,
        reorder: true,
        width: '5rem',
        data: column,
      };

    case 'email':
      return {
        title: t('common.email'),
        key: 'email',
        render: ({ fields, kind }: Entry) => {
          return {
            props: { className: getTableCellClassName(kind) },
            children: (
              <Flex justifyContent="space-between" gap="l">
                {getTruncatedString(fields?.email, 40)}
                {fields?.email ? (
                  <Tooltip
                    title={t('entries.table.settings.columns.email.title')}
                  >
                    <Button
                      onClick={() => {
                        if (fields?.email) {
                          window.open(`mailto:${fields.email}`, '_blank');
                        }
                      }}
                      backgroundColor="transparent"
                      padding="0"
                      margin="0"
                      color="black"
                      iconWidth="2x"
                      icon={faEnvelope}
                    />
                  </Tooltip>
                ) : null}
              </Flex>
            ),
          };
        },
        sorter: (a: Entry, b: Entry) =>
          a.fields?.email.localeCompare(b.fields?.email),
        sortable: true,
        reorder: true,
        data: column,
        width: '8rem',
      };

    default:
      return {
        title: t(`common.${field}`),
        key: field,
        render: (entry: Entry) => {
          const value =
            (
              entry[field as keyof Entry] ||
              entry.fields?.[field as keyof Entry['fields']]
            )?.toString() || '';

          return getTruncatedString(value, 50);
        },
        sortable: true,
        reorder: true,
        data: column,
        width: getFieldWidth(field),
      };
  }
};

export function createEntriesTableColumns<T>(
  columns: EntriesTableSettings['columns'],
  functions: FieldFunctions,
) {
  return columns.map((c) =>
    getFieldInfo(c, functions),
  ) as TableProps<T>['columns'];
}
