import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { KanbanCard } from '../../../model/kanban/KanbanCard';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { KanbanCardUpdateDrawer } from './KanbanCardUpdateDrawer';

const StyledFlex = styled(Flex)`
  flex-wrap: wrap;
  gap: ${Spacing.s};
`;

const BoldText = styled(Text)`
  font-weight: 700;
`;

const ValueText = styled(Text)`
  word-break: break-word;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  border-radius: 0.3rem;
  width: 7rem;
`;

interface KanbanCardReadDrawerProps {
  kanbanCard: KanbanCard | null;
  isOpen: boolean;
  onClose: VoidFunction;
}

export const KanbanCardReadDrawer: React.FC<KanbanCardReadDrawerProps> = ({
  kanbanCard,
  isOpen = false,
  onClose,
}) => {
  const [kanbanCardUpdateDrawerVisible, setKanbanCardUpdateDrawerVisible] =
    useState(false);

  const { t } = useTranslation();

  return (
    <Drawer
      title={t('deals.darwer.preview.title')}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flex={1} flexDirection="column" gap="m">
        <StyledFlex>
          <BoldText>{t('common.id')}:</BoldText>

          <ValueText>{kanbanCard?._id}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('deals.drawer.input.deal_name')}: </BoldText>

          <ValueText>{kanbanCard?.title}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('deals.drawer.input.contact_name')}: </BoldText>
          <ValueText>
            {kanbanCard?.entry?.fields.name || kanbanCard?.entry?.fields.email}
          </ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('common.stage')}: </BoldText>
          <ValueText>{kanbanCard?.order}</ValueText>
        </StyledFlex>

        <StyledFlex>
          <BoldText>{t('common.amount')}:</BoldText>

          <ValueText>{kanbanCard?.amount}$</ValueText>
        </StyledFlex>

        {kanbanCard?.closingDate ? (
          <StyledFlex>
            <BoldText>{t('deals.drawer.input.closing_date')}: </BoldText>

            <ValueText>
              {standardDate(new Date(kanbanCard.closingDate))}
            </ValueText>
          </StyledFlex>
        ) : null}

        {kanbanCard?.note ? (
          <StyledFlex>
            <BoldText>{t('common.description')}:</BoldText>

            <Text wordBreak="break-word">{kanbanCard.note}</Text>
          </StyledFlex>
        ) : null}

        {kanbanCard?.movingReason ? (
          <Flex gap="s" flexWrap="wrap">
            <BoldText>{t('common.moving_reason')}:</BoldText>
            <Flex>
              <Text wordBreak="break-word">{kanbanCard.movingReason}</Text>
            </Flex>
          </Flex>
        ) : null}

        <Flex>
          <StyledButton
            title={t('common.edit')}
            onClick={() => {
              onClose();
              setKanbanCardUpdateDrawerVisible(true);
            }}
          />
        </Flex>
      </Flex>

      <KanbanCardUpdateDrawer
        kanbanCard={kanbanCard!}
        isOpen={kanbanCardUpdateDrawerVisible}
        onClose={() => {
          onClose();
          setKanbanCardUpdateDrawerVisible(false);
        }}
      />
    </Drawer>
  );
};
