import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import Dropdown from 'antd/es/dropdown/dropdown';
import { MenuProps } from 'antd/es/menu/menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Themes, useTheme } from '../../../ThemeContext';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';

const Section = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const SectionTitle = styled(Text)`
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
  padding: ${Spacing.m};
`;

const InnerContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
`;

const LeftSideWrapper = styled(Flex)`
  gap: ${Spacing.xl};
  align-items: center;
  width: 100%;

  @media (min-width: 1200px) {
    width: 75%;
  }
`;

const Title = styled(Text)`
  font-weight: 600;
  font-size: 1.5rem;

  @media (min-width: 1200px) {
    font-size: 1.75rem;
  }
`;

const DropdownButton = styled(Button)`
  background-color: main;
  color: ${(props) => props.theme.black};
  font-size: 0.9rem;
  padding: ${Spacing.s} ${Spacing.xl};
  flex-grow: 1;
`;

export const ProfileChangeTheme: React.FC = () => {
  const { t } = useTranslation();
  const { toggleTheme } = useTheme();

  const themes = {
    light: t('common.light_theme'),
    dark: t('common.dark_theme'),
    yellow: t('common.yellow_theme'),
  };

  const items: MenuProps['items'] = Object.keys(themes).map((key) => {
    const themeKey = key.toUpperCase() as keyof typeof Themes;
    return {
      key,
      label: <Text>{themes[key as keyof typeof themes]}</Text>,
      onClick: () => toggleTheme(Themes[themeKey]),
    };
  });

  return (
    <Section marginBottom="l">
      <SectionTitle>{t('common.theme').toUpperCase()}</SectionTitle>

      <Container>
        <InnerContainer>
          <LeftSideWrapper>
            <AtiraIcon icon={faPalette} size="5x" color="main" />

            <Flex flexDirection="column" gap="s">
              <Title>{t('profile.theme.title')}</Title>

              <Text>{t('profile.theme.paragraph')}</Text>
            </Flex>
          </LeftSideWrapper>

          <Dropdown
            menu={{ items }}
            trigger={['click']}
            arrow={{ pointAtCenter: true }}
            placement="bottom"
          >
            <DropdownButton
              title={t('common.change_theme').toUpperCase()}
            ></DropdownButton>
          </Dropdown>
        </InnerContainer>
      </Container>
    </Section>
  );
};
