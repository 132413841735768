import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../assets';
import { Button } from '../components/Button';
import { Flex } from '../components/Flex';
import { AtiraImage } from '../components/Image';
import { LanguageSwitcher } from '../components/LanguageSwitcher';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';

const Container = styled(Flex)`
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${(props) => props.theme.main};
  padding: ${Spacing.s};
  z-index: 100;
  width: 100%;
  gap: ${Spacing.m};

  @media (min-width: 576px) {
    padding: ${Spacing.s} ${Spacing.l};
  }
`;

const StartButton = styled(Button)`
  text-decoration: none;
  color: ${(props) => props.theme.main};
  padding: ${Spacing.s} ${Spacing.m};
  margin: 0;
  border-radius: ${Rounded.pill};
  background-color: ${(props) => props.theme.white};
  font-size: 0.75rem;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    color: ${(props) => props.theme.black};
  }
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const PublicHeader: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <Container>
      <Flex maxWidth="15rem" flex={1}>
        <AtiraImage src={Images.AtiraSpaceLogo} width="100%" />
      </Flex>

      <Flex gap="m" alignItems="center">
        <LanguageSwitcher />

        <StartButton onClick={() => navigate('/login')}>
          {t('common.login')}
        </StartButton>

        <StartButton onClick={() => navigate('/signup')}>
          {t('common.signup')}
        </StartButton>
      </Flex>
    </Container>
  );
};
