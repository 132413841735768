import { faLifeRing } from '@fortawesome/free-solid-svg-icons/faLifeRing';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../components/Flex';
import { SubHeader } from '../../components/SubHeader';
import { Spacing } from '../../theme/Spacing';
import { ProfileAPIKey } from './components/ProfileAPIKey';
import { ProfileChangePassword } from './components/ProfileChangePassword';
import { ProfileChangeTheme } from './components/ProfileChangeTheme';
import { ProfileDeleteAccount } from './components/ProfileDeleteAccount';
import { ProfileEditCompany } from './components/ProfileEditCompany';
import { ProfileEditPersonal } from './components/ProfileEditPersonal';
import { ProfileGetSupport } from './components/ProfileGetSupport';

export const ProfileEdit: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="m">
      <SubHeader
        onClick={() => {}}
        title={t('profile.header.title')}
        buttonTitle={t('common.help_support')}
        icon={faLifeRing}
      />

      <Flex
        flexDirection="column"
        gap="m"
        style={{ padding: `0 ${Spacing.xxl}` }}
      >
        <ProfileEditPersonal />

        <ProfileEditCompany />

        <ProfileChangePassword />

        <ProfileAPIKey />

        <ProfileDeleteAccount />

        <ProfileGetSupport />

        <ProfileChangeTheme />
      </Flex>
    </Flex>
  );
};
