import {
  faCheck,
  faEnvelope,
  faRotate,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Spinner } from '../../../components/Spinner';
import { Text } from '../../../components/Text';
import { EntryKind } from '../../../model/entry/types/EntryKind.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';
import { EntryBulkConvertModal } from './EntryBulkConvertModal';

const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  gap: ${Spacing.xl};
  background-color: ${({ theme }) => theme.darkPink};
  padding: ${Spacing.o} ${Spacing.s};
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: ${Spacing.s};
`;

const LoadingWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

const ActionTitle = styled(Text)`
  color: ${({ theme }) => theme.white};
  font-weight: bold;
`;

type EntryTableBulkActionsHeaderProps = {
  selctedEntriesIds: React.Key[];
  setSelectedEntriesIds: ATVoidFunction<[]>;
};

export const EntryTableBulkActionsHeader: React.FC<
  EntryTableBulkActionsHeaderProps
> = ({ selctedEntriesIds, setSelectedEntriesIds }) => {
  const [loading, setLoading] = useState(false);
  const [convertBulkModal, setConvertBulkModal] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;

  const onBulkDelete = async () => {
    try {
      setLoading(true);
      await dispatch(
        entryActions.deleteEntryBulk({
          userId,
          entriesIds: selctedEntriesIds as string[],
        }),
      ).unwrap();
      await dispatch(
        entryActions.getLatestEntries({ userId, count: 5, page: 0 }),
      ).unwrap();
      AtiraToast.success(t('entries.table.bulkHeader.delete.success'));
      setSelectedEntriesIds([]);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onBulkConvert = async (kind: EntryKind) => {
    try {
      setLoading(true);
      await dispatch(
        entryActions.convertEntryBulk({
          userId,
          entriesIds: selctedEntriesIds as string[],
          kind: kind,
        }),
      ).unwrap();
      await dispatch(
        entryActions.getLatestEntries({ userId, count: 10, page: 0 }),
      ).unwrap();
      AtiraToast.success(t('entries.table.bulkHeader.convert.success'));
      setSelectedEntriesIds([]);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onBulkTask = async () => {
    try {
      setLoading(true);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <StyledButton
        disabled={loading}
        onClick={onBulkDelete}
        hover
        icon={faTrash}
      >
        <ActionTitle>{t('common.delete')}</ActionTitle>
      </StyledButton>
      <StyledButton
        disabled={loading}
        onClick={() => setConvertBulkModal(true)}
        hover
        icon={faRotate}
      >
        <ActionTitle>{t('common.convert')}</ActionTitle>
      </StyledButton>
      <StyledButton
        style={{ opacity: 0.5 }}
        disabled
        onClick={onBulkTask}
        icon={faCheck}
      >
        <ActionTitle>{t('common.task')}</ActionTitle>
      </StyledButton>
      <StyledButton style={{ opacity: 0.5 }} disabled icon={faEnvelope}>
        <ActionTitle>{t('common.email')}</ActionTitle>
      </StyledButton>

      {loading ? (
        <LoadingWrapper>
          <Spinner size="2rem" />
        </LoadingWrapper>
      ) : null}

      <EntryBulkConvertModal
        isOpen={convertBulkModal}
        onClose={() => setConvertBulkModal(false)}
        onSubmit={onBulkConvert}
        loading={loading}
      />
    </Wrapper>
  );
};
