import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Images } from '../../assets';
import { Button } from '../../components/Button';
import { Flex } from '../../components/Flex';
import { AtiraImage } from '../../components/Image';
import { Text } from '../../components/Text';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { formActions } from '../../redux/forms/form.slice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Shadow } from '../../theme/Shadow';
import { AtiraToast } from '../../utils/AtiraToast';
import { isValidUrl } from '../../utils/String';

const FormSubmitButton = styled(Button)`
  width: 10rem;
  height: 3.3rem;
  font-size: 1.2rem;
  padding: 0;
  box-shadow: ${Shadow.SM};
`;

const FormThemeImageWrapper = styled(Flex)<{ selected: boolean }>`
  border-radius: ${Rounded.md};
  flex-direction: column;
  align-items: center;
  box-shadow: ${Shadow.MD};
  overflow: hidden;
  width: 12rem;
  outline: ${({ theme, selected }) =>
    selected ? `3px solid ${theme.main}` : `none`};
  cursor: pointer;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: fit-content;
  }
`;

const ComingSoonTheme = styled(Flex)`
  background-image: url(${Images.FormTheme1});
  position: relative;
  height: 17rem;
  width: 12rem;
  z-index: 1;
  overflow: hidden;
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.MD};
`;

const ComingSoonThemeText = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
`;

export const FormCreateStepTheme: React.FC = () => {
  const [formCreateloading, setFormCreateLoading] = useState(false);

  const { t } = useTranslation();

  const { handleSubmit, getValues, reset, control, setValue, watch } =
    useFormContext<CreateFormDto>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const loggedinUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;

  const selectedTheme = watch('theme');

  const onSubmit = async () => {
    try {
      setFormCreateLoading(true);

      const dto = getValues();

      const checkedInputs = dto.inputs
        ?.filter((x) => x.required)
        ?.map((x, i) => ({ ...x, order: i }))!;

      const payload = { ...dto, inputs: checkedInputs };

      if (!payload.title.length) {
        return AtiraToast.error(t('forms.form.create.error.missing_title'));
      }

      if (!payload.inputs.length) {
        return AtiraToast.error(
          t('forms.form.create.error.missing_at_least_one_input'),
        );
      }

      if (!payload.domains.length) {
        return AtiraToast.error(
          t('forms.form.create.error.missing_at_least_one_domain'),
        );
      }

      const domains = payload.domains
        .filter((domian) => domian.content.length)
        .map((domain) => {
          let url = isValidUrl(domain.content)
            ? new URL(
                domain.content.startsWith('http')
                  ? domain.content
                  : `https://${domain.content}`,
              ).hostname.replace('www.', '')
            : domain.content;

          const regex = url.match(/[a-z]+\./)?.[0]!;

          if (url.split('.').length - 1 === 2) {
            url = url.replace(regex, '');
          }
          return url;
        });

      payload.domains = domains as any;

      if (payload.postSubmitUrl) {
        payload.postSubmitUrl = `https://${payload.postSubmitUrl}`;
      }

      await dispatch(
        formActions.createForm({
          ...payload,
          userId: loggedinUserId!,
        }),
      ).unwrap();

      await dispatch(
        formActions.getMyForms({ userId: loggedinUserId }),
      ).unwrap();

      reset();

      AtiraToast.success(t('forms.form.create.success'));

      navigate('/forms');
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setFormCreateLoading(false);
    }
  };

  return (
    <Flex flex={1} flexDirection="column" alignItems="center">
      <Flex gap="m">
        <Flex justifyContent="center" flexWrap="wrap" gap="xl">
          <Flex flexDirection="column" alignItems="center" gap="s">
            <FormThemeImageWrapper
              selected={selectedTheme === FormTheme.WHITE}
              onClick={() => setValue('theme', FormTheme.WHITE)}
            >
              <AtiraImage src={Images.FormTheme1} height="17rem" />
            </FormThemeImageWrapper>
            <Text>{t('forms.create.step.4.theme.white')}</Text>
          </Flex>

          <Flex flexDirection="column" alignItems="center" gap="s">
            <FormThemeImageWrapper
              selected={selectedTheme === FormTheme.PURPLE}
              onClick={() => setValue('theme', FormTheme.PURPLE)}
            >
              <AtiraImage src={Images.FormTheme2} height="17rem" />
            </FormThemeImageWrapper>

            <Text>{t('forms.create.step.4.theme.purple')}</Text>
          </Flex>

          <Flex flexDirection="column" alignItems="center" gap="s">
            <FormThemeImageWrapper
              selected={selectedTheme === FormTheme.PURPLE_CURVE}
              onClick={() => setValue('theme', FormTheme.PURPLE_CURVE)}
            >
              <AtiraImage src={Images.FormTheme3} height="17rem" />
            </FormThemeImageWrapper>

            <Text>{t('forms.create.step.4.theme.purple_curve')}</Text>
          </Flex>

          <ComingSoonTheme>
            <ComingSoonThemeText>
              <Text align="center" fontWeight="bolder" fontSize="l">
                {t('form.create.step.3.themes_coming_soon')}
              </Text>
            </ComingSoonThemeText>
          </ComingSoonTheme>
        </Flex>
      </Flex>

      <FormSubmitButton
        onClick={handleSubmit(onSubmit)}
        loading={formCreateloading}
      >
        {t('forms.create_form')}
      </FormSubmitButton>
    </Flex>
  );
};
