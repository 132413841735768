import Radio from 'antd/es/radio/radio';
import { TFunction } from 'i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { isValidUrl } from '../../utils/String';

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding-top: ${Spacing.l};
  border-top: 2px solid ${({ theme }) => theme.darkerSub};
  gap: ${Spacing.m};
  order: 2;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    border-right: ${({ theme }) =>
      i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
    border-left: ${({ theme }) =>
      i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};
    padding-right: ${Spacing.xl};
    padding-top: 0;
    border-top: 0;
    width: 63%;
    order: 1;
  }
`;

const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.m};
  order: 1;

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 35%;
    order: 2;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.darkSub};
  font-size: 1.2rem;
`;

const Title = styled(Text)`
  font-weight: bolder;
  color: ${({ theme }) => theme.textColor};
`;

const Description = styled(Text)`
  font-size: 0.9rem;
  color: ${(props) => props.theme.darkerSub};
`;

const StyledRadio = styled(Radio)`
  font-size: 1.1rem;
`;

const InputWrapper = styled(Flex)`
  padding: ${Spacing.m};
  border: 2px solid ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.md};
  margin-left: ${Spacing.l};
`;

const postSubmitURLValidate = (url: string, t: TFunction) => {
  if (!isValidUrl(url)) {
    return t('forms.create.step.3.error.invalid_url');
  }

  return true;
};

export const FormCreateStepPostSubmitType: React.FC = () => {
  const { watch, control } = useFormContext<CreateFormDto>();

  const { t } = useTranslation();

  const postSubmit = watch('postSubmit');

  return (
    <Flex gap={'m'} alignItems="center" width={'100%'} flexWrap="wrap">
      <LeftSideWrapper>
        <Controller
          control={control}
          name="postSubmit"
          render={({ field: { onChange, value } }) => (
            <StyledRadio
              onChange={() => onChange(FormPostSubmit.confirm)}
              checked={value === FormPostSubmit.confirm}
              value={FormPostSubmit.confirm}
            >
              {t('forms.create.step.3.radio.confirmation')}
            </StyledRadio>
          )}
        />

        <InputWrapper>
          <Controller
            control={control}
            name="postSubmitMessage"
            rules={{
              required: postSubmit === FormPostSubmit.confirm,
              maxLength: 280,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                title={t('forms.create.step.3.input.confirmation.label')}
                placeholder={`${t('common.example')}: ${t('common.thank_you')}`}
                valid={!error}
                errorMessage={error?.message}
                maxLength={280}
              />
            )}
          />
        </InputWrapper>

        <Controller
          control={control}
          name="postSubmit"
          render={({ field: { onChange, value } }) => (
            <StyledRadio
              checked={value === FormPostSubmit.redirect}
              onChange={() => onChange(FormPostSubmit.redirect)}
              value={FormPostSubmit.redirect}
            >
              {t('forms.create.step.3.radio.redirect')}
            </StyledRadio>
          )}
        />

        <InputWrapper>
          <Controller
            control={control}
            name="postSubmitUrl"
            rules={{
              required: postSubmit === FormPostSubmit.redirect,
              validate: (url) => postSubmitURLValidate(url!, t),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                title={t('forms.create.step.3.input.label.url')}
                placeholder={`${t('common.example')}: www.example.com/thanks`}
                valid={!error}
                errorMessage={error?.message}
                required
              />
            )}
          />
        </InputWrapper>
      </LeftSideWrapper>

      <RightSideWrapper>
        <Flex gap="s" flexWrap="wrap">
          <Title>{t('forms.create.step.3.radio.confirmation')}:</Title>

          <Description wordBreak="break-word">
            {t('forms.create.step.3.radio.confirm.description')}
          </Description>
        </Flex>

        <Flex gap="s" flexWrap="wrap">
          <Title>{t('forms.create.step.3.radio.redirect')}:</Title>

          <Description wordBreak="break-word">
            {t('forms.create.step.3.radio.redirect.description')}
          </Description>
        </Flex>
      </RightSideWrapper>
    </Flex>
  );
};
