import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { Tooltip } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { SortableChild } from '../../../components/dnd/SortableChild';
import i18n, { AppLangs } from '../../../i18n';
import { KanbanCard as KanbanCardModel } from '../../../model/kanban/KanbanCard';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { Rounded } from '../../../theme/Rounded';
import { Spacing } from '../../../theme/Spacing';
import { standardDate } from '../../../utils/Date';
import { KanbanCardReadDrawer } from './KanbanCardReadDrawer';
import { KanbanCardUpdateDrawer } from './KanbanCardUpdateDrawer';

const StyledKanbanCard = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.m};
  padding: ${Spacing.s};
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.xl};
  width: 100%;
`;

const KanbanCardActionsWrapper = styled(Flex)`
  gap: ${Spacing.s};
  align-items: center;
`;

const KnabanCardName = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const KanbanCardAction = styled(Button)`
  padding: 0;
  background-color: ${(props) => props.theme.transparent};
  margin: 0;
`;

type EntriesDealsDealPros = {
  card: KanbanCardModel;
  column?: KanbanColumn;
};

export const KanbanCard: React.FC<EntriesDealsDealPros> = ({
  card,
  column,
}) => {
  const [kanbanCardUpdateDrawerVisible, setKanbanCardUpdateDrawerVisible] =
    useState(false);
  const [kanbanCardReadDrawerVisisble, setKanbanCardReadDrawerVisisble] =
    useState(false);

  const actionsMarginSide = {
    marginRight:
      i18n.language === AppLangs.AR || column?.default ? '0' : '1.1rem',
    marginLeft:
      i18n.language === AppLangs.AR && !column?.default ? Spacing.l : '0',
  };

  return (
    <SortableChild id={card._id} showDragIcon={!column?.default} key={card._id}>
      <StyledKanbanCard>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          gap="s"
          width={'100%'}
        >
          <Flex justifyContent="space-between">
            <Text cutText>{card.amount}$</Text>

            <KanbanCardActionsWrapper style={actionsMarginSide}>
              <KanbanCardAction
                onClick={() => setKanbanCardReadDrawerVisisble(true)}
              >
                <Tooltip>
                  <AtiraIcon color="main" icon={faEye} />
                </Tooltip>
              </KanbanCardAction>
              <KanbanCardAction
                onClick={() => setKanbanCardUpdateDrawerVisible(true)}
              >
                <Tooltip>
                  <AtiraIcon color="main" icon={faPenToSquare} />
                </Tooltip>
              </KanbanCardAction>
            </KanbanCardActionsWrapper>
          </Flex>

          <Flex>
            <Flex width={'50%'}>
              <KnabanCardName cutText>{card.title}</KnabanCardName>
            </Flex>
            <Flex width={'50%'} justifyContent="flex-end"></Flex>
          </Flex>
          <Flex flexDirection="column" width={'100%'}>
            <Text cutText>
              {card.entry?.fields?.name || card.entry?.fields?.email}
            </Text>
          </Flex>
        </Flex>

        {card.closingDate ? (
          <Flex justifyContent="flex-end">
            <Text>
              {standardDate(new Date(card.closingDate).toISOString())}
            </Text>
          </Flex>
        ) : null}
      </StyledKanbanCard>

      <KanbanCardUpdateDrawer
        key={card._id}
        isOpen={kanbanCardUpdateDrawerVisible}
        onClose={() => setKanbanCardUpdateDrawerVisible(false)}
        kanbanColumn={column}
        kanbanCard={card}
      />

      <KanbanCardReadDrawer
        isOpen={kanbanCardReadDrawerVisisble}
        kanbanCard={card}
        onClose={() => setKanbanCardReadDrawerVisisble(false)}
      />
    </SortableChild>
  );
};
