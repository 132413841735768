import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationAR from './locales/ar.json';
import translationEN from './locales/en.json';
import translationTR from './locales/tr.json';

export enum AppLangs {
  AR = 'ar',
  EN = 'en',
  TR = 'tr',
}

const resources: Record<string, Resource> = {
  en: { translation: translationEN },
  ar: { translation: translationAR },
  tr: { translation: translationTR },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('atiraspace_lang') || AppLangs.EN,
  fallbackLng: AppLangs.EN,
  interpolation: { escapeValue: false },
});

i18n.on('languageChanged', (lang) => {
  window.location.reload();
  window.localStorage.setItem('atiraspace_lang', lang);

  // const doc = document.documentElement;

  // doc.setAttribute('lang', lang);

  // switch (lang) {
  //   case AppLangs.AR: {
  //     doc.classList.remove('atira-english-font');
  //     doc.classList.add('atira-arabic-font');
  //     break;
  //   }

  //   default: {
  //     doc.classList.remove('atira-arabic-font');
  //     doc.classList.add('atira-english-font');
  //     break;
  //   }
  // }
});

export default i18n;
