import { faHelicopterSymbol } from '@fortawesome/free-solid-svg-icons/faHelicopterSymbol';
import { updatedDiff } from 'deep-object-diff';
import pick from 'lodash/pick';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../../ThemeContext';
import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { UpdateUserDto } from '../../../model/user/dto/UpdateUserDto';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { userActions } from '../../../redux/user/user.slice';
import { Breakpoints } from '../../../theme/Breakpoints';
import { Rounded } from '../../../theme/Rounded';
import { Shadow } from '../../../theme/Shadow';
import { Spacing } from '../../../theme/Spacing';
import { AtiraToast } from '../../../utils/AtiraToast';

const Section = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const SectionTitle = styled(Text)`
  font-weight: 600;
  color: ${(props) => props.theme.main};
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.white};
  border-radius: ${Rounded.md};
  box-shadow: ${Shadow.SM};
  padding: ${Spacing.m};
  flex-wrap: wrap;
`;

const LeftSideWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${Spacing.s};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 33%;
  }
`;

const SaveButton = styled(Button)`
  height: 2.5rem;
  width: 8.5rem;
  font-size: 0.9rem;
  margin: -${Spacing.m} 0 0 auto;
  padding: 0 ${Spacing.s};
`;

const ChangeButton = styled(Button)`
  font-size: 1.1rem;
  background: ${(props) => props.theme.lightSub};
  padding: ${Spacing.s};
  margin: 0;
  transition: border-bottom 0.3s;
  border: 1px solid ${(props) => props.theme.transparent};
  color: ${(props) => props.theme.black};
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.sub};
    border: 1px solid ${(props) => props.theme.darkSub};
  }
`;

const StyledInput = styled(Input)`
  width: 20rem;
  height: 3rem;
  border: 1px solid ${(props) => props.theme.darkSub};
  font-size: 1rem;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.black};
  }

  @media (min-width: ${Breakpoints.DESKTOP}) {
    height: 3.6rem;
  }
`;

export const ProfileEditCompany: React.FC = () => {
  const [companyInfoUpdateLoading, setCompanyInfoUpdateLoading] =
    useState(false);

  const { t } = useTranslation();

  const { theme } = useTheme();

  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser)!;

  const { control, handleSubmit, getValues } = useForm<
    Omit<UpdateUserDto, 'userId' | 'name' | 'phone'>
  >({
    defaultValues: {
      company: loggedInUser.company,
      website: loggedInUser.website,
    },
  });

  const dispatch = useAppDispatch();

  const updateUserCompanyInfo = async () => {
    try {
      setCompanyInfoUpdateLoading(true);

      const dto = getValues();

      const baseDto: Partial<UpdateUserDto> = pick(loggedInUser, [
        'company',
        'website',
      ]);

      const changedDto = updatedDiff(baseDto, dto) as UpdateUserDto;

      if (Object.keys(changedDto).length === 0) {
        AtiraToast.success(t('profile.company.update.success'));
      } else {
        changedDto.userId = loggedInUser._id;

        await dispatch(userActions.updateUserInfo(changedDto)).unwrap();
        await dispatch(
          userActions.getLoggedInUser({ userId: loggedInUser._id }),
        ).unwrap();

        AtiraToast.success(t('profile.company.update.success'));
      }
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setCompanyInfoUpdateLoading(false);
    }
  };

  return (
    <Section>
      <SectionTitle>{t('profile.title.company_info')}</SectionTitle>

      <Container>
        <LeftSideWrapper>
          <Flex backgroundColor={theme.sub} borderRadius={'circle'} padding="s">
            <AtiraIcon icon={faHelicopterSymbol} size="7x" color="main" />
          </Flex>

          <ChangeButton title={t('profile.change_logo')} disabled />
        </LeftSideWrapper>

        <Flex flexDirection="column" gap="l" flexGrow={1}>
          <Controller
            name="company"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                type="text"
                placeholder={t('common.company')}
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                type="text"
                placeholder={t('common.website')}
              />
            )}
          />
          <StyledInput type="phone" placeholder={t('common.phone')} disabled />

          <SaveButton
            title={t('common.save_changes').toUpperCase()}
            onClick={handleSubmit(updateUserCompanyInfo)}
            loading={companyInfoUpdateLoading}
          />
        </Flex>
      </Container>
    </Section>
  );
};
