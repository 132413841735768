/* eslint-disable no-nested-ternary */
import { Radio, Rate } from 'antd';
import { t } from 'i18next';
import { isDate } from 'lodash';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../components/AtiraDatePicker';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { Flex } from '../../../components/Flex';
import { AtiraImage } from '../../../components/Image';
import { ImageUpload } from '../../../components/ImageUpload';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { UpdateContactTaskDto } from '../../../model/entry/dto/UpdateContactTaskDto';
import { ContactTaskPriority } from '../../../model/entry/types/ContactTaskPriority.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';
import { getMediaUrl } from '../../../utils/Media';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 9rem;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

const StyledRadioHigh = styled(Radio.Button)`
  border: 1px solid red !important;
  color: red !important;

  &.ant-radio-button-wrapper-checked {
    background-color: red !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const StyledRadioMedium = styled(Radio.Button)`
  border: 0 !important;
  border-bottom: 1px solid orange !important;
  border-top: 1px solid orange !important;
  color: orange !important;

  &.ant-radio-button-wrapper-checked {
    background-color: orange !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const StyledRadioLow = styled(Radio.Button)`
  border: 1px solid green !important;
  color: green !important;

  &.ant-radio-button-wrapper-checked {
    background-color: green !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const TaskImage = styled(AtiraImage)`
  max-width: 4rem;
  max-height: 4rem;
  width: 100%;
  height: 100%;
  align-self: center;
`;

const validateTaskName = (value: String) => {
  if (!value.trim() || value.trim().length < 3) {
    return t('task.drawer.error.short_name');
  }
};

interface TaskUpdateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
}

export const TaskUpdateDrawer: React.FC<TaskUpdateDrawerProps> = ({
  isOpen = false,
  entry,
  onClose,
}) => {
  const [taskUpdateLoading, setTaskUpdateLoading] = useState(false);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId);

  const dispatch = useAppDispatch();

  const { control, getValues, reset, handleSubmit } =
    useForm<UpdateContactTaskDto>();

  const updateTask = async () => {
    try {
      setTaskUpdateLoading(true);

      const dto = getValues();

      if (dto.deadline) {
        dto.deadline = new Date(dto.deadline).getTime();
      }

      await dispatch(
        entryActions.updateTask(omitBy(dto, isNil) as UpdateContactTaskDto),
      ).unwrap();
      await dispatch(
        entryActions.getEntryById({ userId: userId!, entryId: entry?._id! }),
      ).unwrap();

      onClose();

      AtiraToast.success(t('task.update.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setTaskUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        name: entry?.contactTask?.name,
        deadline: entry?.contactTask?.deadline || undefined,
        description: entry?.contactTask?.description || '',
        taskId: entry?.contactTask?._id,
        userId: userId,
        ownerId: userId,
        priority: entry?.contactTask?.priority,
        rating: entry?.contactTask?.rating || 0,
      });
    }
  }, [
    entry?.contactTask?._id,
    entry?.contactTask?.deadline,
    entry?.contactTask?.description,
    entry?.contactTask?.name,
    entry?.contactTask?.priority,
    entry?.contactTask?.rating,
    isOpen,
    reset,
    userId,
  ]);

  return (
    <Drawer
      destroyOnClose
      title={`${t('task.update.drawer.title')} - ${entry?.contactTask?.name}`}
      open={isOpen}
      onClose={onClose}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            validate: (value: string) => validateTaskName(value),
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('task.create.input.label.name')}
              required
              valid={!error}
              errorMessage={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="deadline"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker
              title={t('task.create.input.label.deadline')}
              onChange={onChange}
              value={value || null}
            />
          )}
        />

        <Flex flexDirection="column" gap="s">
          <Text>{t('common.priority')}</Text>
          <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange } }) => (
              <Radio.Group onChange={onChange} value={value}>
                <StyledRadioHigh value={ContactTaskPriority.HIGH}>
                  {t('common.high')}
                </StyledRadioHigh>
                <StyledRadioMedium value={ContactTaskPriority.MEDIUM}>
                  {t('common.medium')}
                </StyledRadioMedium>
                <StyledRadioLow value={ContactTaskPriority.LOW}>
                  {t('common.low')}
                </StyledRadioLow>
              </Radio.Group>
            )}
          />
        </Flex>

        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <StyledTextarea
              rows={3}
              title={t('common.description')}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Flex flexDirection="column" gap="s">
          <Text>{t('task.update.image.title')}</Text>
          {entry?.contactTask?.image ? (
            <TaskImage src={getMediaUrl(entry.contactTask.image)} />
          ) : null}
          <Controller
            control={control}
            name="image"
            render={({ field: { value, onChange } }) => (
              <ImageUpload onChange={(f) => onChange(f)} />
            )}
          />
        </Flex>

        <Flex flexDirection="column" gap="s">
          <Text>{t('task.update.rating.title')}</Text>
          <Controller
            control={control}
            name="rating"
            render={({ field: { value, onChange } }) => (
              <Rate count={10} value={value} onChange={onChange} />
            )}
          />
        </Flex>

        <Flex>
          <StyledButton
            onClick={handleSubmit(updateTask)}
            title={t('common.update')}
            loading={taskUpdateLoading}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
