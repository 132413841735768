import { Radio } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../components/AtiraDatePicker';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { DropDown } from '../../../components/DropDown';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { Text } from '../../../components/Text';
import { Textarea } from '../../../components/Textarea';
import { Entry } from '../../../model/entry/Entry';
import { CreateContactTaskDto } from '../../../model/entry/dto/CreateTaskDto';
import { ContactTaskPriority } from '../../../model/entry/types/ContactTaskPriority.enum';
import { entryActions } from '../../../redux/entry/entry.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledInput = styled(Input)`
  height: 2.5rem;
  border: 2px solid ${(props) => props.theme.main};
`;

const StyledTextarea = styled(Textarea)`
  border: 2px solid ${(props) => props.theme.main};
  max-height: 9rem;
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  width: 7rem;
`;

const StyledRadioP0 = styled(Radio.Button)`
  border: 1px solid red !important;
  color: red !important;

  &.ant-radio-button-wrapper-checked {
    background-color: red !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const StyledRadioP1 = styled(Radio.Button)`
  border: 0 !important;
  border-bottom: 1px solid orange !important;
  border-top: 1px solid orange !important;
  color: orange !important;

  &.ant-radio-button-wrapper-checked {
    background-color: orange !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const StyledRadioP2 = styled(Radio.Button)`
  border: 1px solid green !important;
  color: green !important;

  &.ant-radio-button-wrapper-checked {
    background-color: green !important;
    color: ${(props) => props.theme.white} !important;
  }
  &.ant-radio-button-wrapper::before {
    display: none;
  }
`;

const validateTaskName = (value: string) => {
  if (!value.trim() || value.trim().length < 3) {
    return t('task.drawer.error.short_name');
  }
};

interface TaskCreateDrawerProps {
  isOpen: boolean;
  onClose: VoidFunction;
  entry: Entry | null;
}

export const ContactTaskCreateDrawer: React.FC<TaskCreateDrawerProps> = ({
  isOpen = false,
  entry,
  onClose,
}) => {
  const [taskCreateLoading, setTaskCreateLoading] = useState(false);

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId);

  const dispatch = useAppDispatch();

  const { control, getValues, reset, handleSubmit } =
    useForm<CreateContactTaskDto>();

  const createTask = async () => {
    try {
      setTaskCreateLoading(true);

      const dto = getValues();

      if (dto.deadline) {
        dto.deadline = new Date(dto.deadline).getTime();
      }

      await dispatch(entryActions.createTask(dto)).unwrap();
      await dispatch(
        entryActions.getEntryById({ userId: userId!, entryId: entry?._id! }),
      ).unwrap();

      localOnClsoe();
      AtiraToast.success(t('task.create.success'));
    } catch (e: any) {
      console.log(e);
      AtiraToast.apiError(e);
    } finally {
      setTaskCreateLoading(false);
    }
  };

  const localOnClsoe = () => {
    onClose();
    reset({
      entryId: '',
      userId: userId,
      ownerId: userId,
      priority: ContactTaskPriority.MEDIUM,
      description: '',
      deadline: undefined,
      done: undefined,
      name: '',
      reminder: undefined,
    });
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        entryId: entry?._id || '',
        userId: userId,
        ownerId: userId,
        priority: ContactTaskPriority.MEDIUM,
        description: '',
        deadline: undefined,
        done: undefined,
        name: '',
        reminder: undefined,
      });
    }
  }, [entry, isOpen, reset, userId]);

  return (
    <Drawer
      title={t('task.create.drawer.title')}
      open={isOpen}
      onClose={localOnClsoe}
    >
      <Flex flexDirection="column" gap="m">
        <DropDown
          title={t('task.create.leads_dropdown.title')}
          disabled
          options={[
            {
              label: entry?.fields.name || entry?.fields.email,
              value: entry?._id,
            },
          ]}
          value={{
            label: entry?.fields.name || entry?.fields.email,
            value: entry?._id,
          }}
          onChange={() => {}}
        />

        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
            validate: (value: string) => validateTaskName(value),
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('task.create.input.label.name')}
              required
              valid={!error}
              errorMessage={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="deadline"
          render={({ field: { value, onChange } }) => (
            <AtiraDatePicker onChange={onChange} value={value || null} />
          )}
        />

        <Flex flexDirection="column" gap="s">
          <Text>{t('common.priority')}</Text>
          <Controller
            control={control}
            name="priority"
            render={({ field: { value, onChange } }) => (
              <Radio.Group onChange={onChange} value={value}>
                <StyledRadioP0 value={ContactTaskPriority.HIGH}>
                  {t('common.high')}
                </StyledRadioP0>
                <StyledRadioP1 value={ContactTaskPriority.MEDIUM}>
                  {t('common.medium')}
                </StyledRadioP1>
                <StyledRadioP2 value={ContactTaskPriority.LOW}>
                  {t('common.low')}
                </StyledRadioP2>
              </Radio.Group>
            )}
          />
        </Flex>

        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <StyledTextarea
              rows={3}
              title={t('common.description')}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Flex>
          <StyledButton
            onClick={handleSubmit(createTask)}
            title={t('common.create')}
            loading={taskCreateLoading}
          />
        </Flex>
      </Flex>
    </Drawer>
  );
};
