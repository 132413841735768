import { AtiraRouteNotLoggedIn } from '../AtiraRouteNotLoggedIn';
import { PublicLayout } from '../PublicLayout';
import { Features } from '../features/Features';
import { Login } from '../loginSignup/Login';
import { ForgotPassword } from '../loginSignup/forgot-password/ForgotPassword';
import { Signup } from '../loginSignup/signup/Signup';
import { Templates } from '../templates/Template';

export const PublicRouter = [
  {
    element: <PublicLayout />,
    errorElement: (
      <div>Something went wrong... Please try refreshing the page</div>
    ),
    children: [
      {
        path: '/features',
        element: (
          <AtiraRouteNotLoggedIn
            component={<Features />}
            redirect="/help/features"
          />
        ),
      },
      {
        path: '/templates',
        element: (
          <AtiraRouteNotLoggedIn
            component={<Templates />}
            redirect="/help/templates"
          />
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <AtiraRouteNotLoggedIn component={<Login />} />,
  },
  {
    path: '/signup',
    element: <AtiraRouteNotLoggedIn component={<Signup />} />,
  },
  {
    path: '/forgot-password',
    element: <AtiraRouteNotLoggedIn component={<ForgotPassword />} />,
  },
];
